import React, { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import {
    auth,
    RegisterWithEmailAndPassword,
    SignInWithApple,
    SignInWithFacebook,
    SignInWithGoogle,
    users
} from "../../utils/firebase.utils";
import { Theme, useTheme } from "@material-ui/core";
import Form from "../../components/forms/form-wrapper";
import FormInput from "../../components/forms/form-input";
import Text from "../../components/data-display/text";
import AuthContainer from "../../components/data-display/AuthContainer";
import Button from "../../components/buttons/primary-button";
import InlineLink from "../../components/data-display/inline-link";
import GoogleIcon from "../../images/icons/google.png";
import FacebookIcon from "../../images/icons/facebook.png";
import AppleIcon from "../../images/icons/apple.png";
import FormErrorText from "../../components/data-display/form-error-text";
import SecondaryButton from "../../components/buttons/secondary-button";
import { useSelector } from "react-redux";
import * as queryString from "query-string";
import { RootState } from "../../redux/index.reducers";
import { UserData } from "../../models/user-data";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PlayStore from "../../images/icons/google_playstore.svg";
import AppStore from "../../images/icons/apple-appstore.svg";
import { navigate,Link } from "gatsby"; 
import { baseUrl } from "../../utils/constant.urls";
import axios from "axios";
import {Router,useLocation} from '@reach/router';
import firebase from "firebase";
import { NoAuthRoute } from "../../components/app-routes/app-routes";
import SEO from "../../components/seo/seo";
const useStyles = makeStyles((theme: Theme) => ({
    background: {
        display: "flex",
        background: theme.palette.background.default,
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            justifyContent: "flex-start"
        }
    }
}));

function Register({ location }: { location: Location; }) {
    const classes = useStyles();
    const theme = useTheme();
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
    const [error, setError] = useState("");
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState<string>("");
    const { isAuthenticated } = useSelector((state: RootState) => state.userData);

    useEffect(() => {
        auth
            .getRedirectResult()
            .then(async (result) => {
                if (result.operationType === "signIn") {
                    let user = result.user;
                    if (user) {
                        setAuthenticated(true);
                    }
                     else {
                      navigate('/register');
                    }
                } else {
                }
            })
            .catch((error) => {
                console.log(error);
                setError(error.message);
            });
    });

    useEffect(() => {
        if (isAuthenticated) setAuthenticated(true);
    }, [isAuthenticated]);

    // Handle Register
    const register = (values: Record<string, string>) => {
        return new Promise((resolve, reject) => {
            const numberRegx = new RegExp(/\d/);
            const upperCaseRegx = new RegExp(/^(?=.*[A-Z])/);
            const symbolRegx = new RegExp(/(?=.*[@$!%*#?&+-\/])/);
            const fullname = values?.firstName.trim() + values?.lastName.trim();
            if (fullname?.length > 25) {
                return reject("Full name must be less than or equal to 25 characters");
            } else if (values["newPassword"].length < 8) {
                return reject({ newPassword: "Must be 8+ characters" });
            } else if (!(values["newPassword"].match(numberRegx))) {
                return reject({ newPassword: "Must contain at-least one number" });
            } else if (!(values["newPassword"].match(upperCaseRegx))) {
                return reject({ newPassword: "Must contain at-least one uppercase" });
            } else if (!(values["newPassword"].match(symbolRegx))) {
                return reject({ newPassword: "Must contain at-least one symbol" });
            } else {
                setLoading("register");
                RegisterWithEmailAndPassword({ email: values["email"], password: values["newPassword"] })
                    .then(async (result) => {
                        let user = result.user;
                        if (user) {
                            let userData: UserData = new UserData({
                                uid: user?.uid,
                                email: user?.email,
                                firstName: values?.firstName.trim(),
                                lastName: values?.lastName.trim(),
                            });
                            await users.doc(user.uid).set(userData.toPublicData());
                            let newUser = auth?.currentUser;
                            axios
                            .post(
                                `${baseUrl}/sendVerificationEmail`,
                                {
                                    email: newUser.email
                                }
                            )
                            .then(() => {
                                resolve('success')
                                console.log('success')
                            })
                            .catch(async (err) => {
                                console.log(err.code);
                            });
                            firebase.analytics().logEvent("sign_up");
                            setAuthenticated(true);
                        } else {
                            reject("Something went wrong on our side.");
                        }
                    })
                    .catch((err) => {
                        setLoading("")
                        switch (err.code) {
                            case "auth/email-already-in-use":
                                return reject({ email: "Account already exists." });
                            case "auth/weak-password":
                                return reject({ password: "Password must contain 8+ characters, a symbol & a number." });
                            case "auth/invalid-email":
                                return reject({ email: "Please enter a valid Email Address." });
                            default:
                                return reject("Something went wrong on our side.");
                        }
                    });
            }
        });
    };

    // Handle Apple Sign In
    const loginWithApple = () => {
        <Link to="/login?auth=apple"/>
        try {
            setLoading("appleLogin");
            SignInWithApple()
                .then(() => {
                    setLoading("");
                })
                .catch((err) => {
                    let errorMessage = err.message;
                    setError(errorMessage);
                    setLoading("");
                });
        } catch (e) {
            let errorMessage = e.message;
            setError(errorMessage);
            setLoading("");
        }
    };

    // Handle Google Sign In
    const loginWithGoogle = () => {
        <Link to="/register?auth=google"/>

        try {
            setLoading("googleLogin");
            SignInWithGoogle()
                .then(() => { })
                .catch((err) => {
                    let errorMessage = err.message;
                    setError(errorMessage);
                });
        } catch (e) {
            let errorMessage = e.message;
            setError(errorMessage);
        }
    };

    // Handle Facebook Login
    const loginWithFacebook = () => {
        <Link to="/register?auth=facebook"/>
        try {
            setLoading("facebookLogin");
            SignInWithFacebook()
                .then(() => { })
                .catch((err) => {
                    let errorMessage = err.message;
                    setError(errorMessage);
                });
        } catch (e) {
            let errorMessage = e.message;
            setError(errorMessage);
        }
    };
    return (
        <>
    <SEO title={'Sydetasker - Post. Hire. Done.'} pathname={"/register"} description={""}  />
        <div className={classes.background}>
            {authenticated &&  navigate(queryString.parse(location.search).redirect ? (queryString.parse(location.search).redirect as string) : "/tasks")}
            <div style={{ marginTop: xsDown ? "45px" : "160px" }} />
            <Grid justify={"center"} component={"div"} container>
                <Grid component={"div"} xl={5} lg={5} md={6} sm={6} xs={12} item style={{ display: "flex", justifyContent: "center" }}>
                    <AuthContainer padding>
                        <Form initialValues={
                            {
                                email:"",
                                firstName:"",
                                lastName:"",
                                newPassword:""

                            }
                        }  disableLoading onSubmit={register} style={{ display: "flex", justifyContent: "center" }}>
                            <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between", marginBottom: "8px" }}>
                                <Text variant={"h5"}>Sign Up</Text>
                                <Text variant={"body2"}>
                                    <span style={{ opacity: "0.6", marginRight: "8px" }}>Have an account?</span>
                                    <Text variant={"body2"} medium component={"span"}>
                                        <InlineLink to={"/login"} style={{ color: theme.palette.primary.main }} variant={"primary"}>
                                            Sign In
                                        </InlineLink>
                                    </Text>
                                </Text>
                            </div>
                            <div style={{ display: "flex" }}>
                                <FormInput placeholder={"First Name"} name={"firstName"} type={"text"}  />
                                <div style={{ width: "16px" }} />
                                <FormInput placeholder={"Last Name"} name={"lastName"} type={"text"}  />
                            </div>
                            <FormInput  placeholder={"Enter your email"} name={"email"} type={"email"}  />
                            <FormInput placeholder={"Set Password"} name={"newPassword"} type={"password"}  />
                            <FormErrorText error={error} showError={error !== ""} />
                            <Button type={"submit"} key={"button5"} style={{ marginTop: "6px" }}>
                                {" "}Join Sydetasker{" "}
                            </Button>
                            <div style={{ display: "flex", margin: "8px 5%", alignItems: "center" }}>
                                <div style={{ flex: "1", height: "2px", background: "grey", opacity: "0.1" }} />
                                <Text variant={"body2"} medium component={"span"} style={{ color: "grey", margin: "0 12px" }}>
                                    or
                                </Text>
                                <div style={{ flex: "1", height: "2px", background: "grey", opacity: "0.1" }} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <SecondaryButton type="button" key={"apple_login"} isLoading={loading === "appleLogin"} background={"rgb(10, 10, 10)"} onClick={loginWithApple} textColor={"white"} icon={AppleIcon}>
                                    Continue with Apple
                                </SecondaryButton>
                                <div style={{ height: "10px" }} />
                                <SecondaryButton key={"facebook_login"} type={"button"} background={"rgb(66, 103, 178)"} isLoading={loading === "facebookLogin"} onClick={loginWithFacebook} textColor={"white"} icon={FacebookIcon}>
                                    Continue with Facebook
                                </SecondaryButton>
                                <div style={{ height: "10px" }} />
                                <SecondaryButton key={"google_login"} type={"button"} background={"white"} isLoading={loading === "googleLogin"} onClick={loginWithGoogle} textColor={"rgb(40,40,40)"} icon={GoogleIcon}>
                                    Continue with Google
                                </SecondaryButton>
                            </div>
                            <div style={{ height: "12px" }} />
                            <Text variant={"caption"} medium component={"span"} style={{ color: "grey", width: "80%", margin: "0 auto" }}>
                                By clicking Join Sydetasker, Continue with Apple, Continue with Facebook or Continue with Google, you agree to our{" "}
                                <InlineLink  to={"/terms"} style={{ color: theme.palette.primary.main }} variant={"primary"}>
                                    Terms & Conditions
                                </InlineLink>{" "}
                                and{" "}
                                <InlineLink  to={"/privacy-policy"} style={{ color: theme.palette.primary.main }} variant={"primary"}>
                                    Privacy Policy
                                </InlineLink>
                            </Text>
                        </Form>
                        {xsDown &&
                            <div style={{ display: "flex", position: "relative", width: "100%", justifyContent: "center", marginTop: "22px" }}>
                                <a href={"https://play.google.com/store/apps/details?id=ai.unicode.sydetasker"}>
                                    <img loading={"lazy"}  src={PlayStore} alt={""} />
                                </a>
                                <div style={{ width: "18px" }} />
                                <a href={"https://apps.apple.com/in/app/sydetasker/id1502353211#?platform=iphone"}>
                                    <img loading={"lazy"}  src={AppStore} alt={""} />
                                </a>
                            </div>
                        }
                    </AuthContainer>
                </Grid>
            </Grid>
            <div style={{ marginTop: xsDown ? "45px" : "85px" }} />
        </div>
        </>
    );
}

const App = () => {
    const location= useLocation()
    return (
      <div className="content">
          <Router location={location}>
            <NoAuthRoute path={"/register"} component={Register}  />
          </Router>
      </div>
    );
  }
  
  export default App;